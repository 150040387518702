import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit{
  @ViewChild('drawer') drawer: MatDrawer;

  preferencesform: FormGroup = new FormGroup({
    modified: new FormControl(true),
    reachFinalState: new FormControl(true),
    frequency: new FormControl(0)
  });

  constructor(private usersService: UsersService) {

  }

  ngOnInit() {
    this.loadPreferences();
  }

  onOpenPreferences() {
    this.drawer.toggle();
  }

  updatePreferences() {
    let data = this.preferencesform.value;
    this.usersService.changeUserPreferences(data).subscribe(_ => {
      this.drawer.close();
      this.loadPreferences();
    })
  }

  close() {
    this.drawer.close();
  }

  private loadPreferences() {
    this.usersService.getPreferences().subscribe(response => {
      if (!response.isError) {
        this.preferencesform.setValue({
          modified: response.data.modified,
          reachFinalState: response.data.reachFinalState,
          frequency: response.data.frequency
        });
      }
    });
  }

}
